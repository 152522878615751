import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";

const SEO = ({ path, title }) => {
  const intl = useIntl();
  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            siteName,
            siteUrl,
            type,
            imageAlt,
            twitterHandle,
            twitterTitle,
            twitterDescription,
          },
        },
        image: {
          childImageSharp: {
            fluid: { src, presentationWidth, presentationHeight },
          },
        },
      }) => {
        const url = `${siteUrl}${path || "/"}`;
        const imageUrl = `${siteUrl}${src}`;

        return (
          <Helmet htmlAttributes={{ lang: intl.locale }} title={title}>
            <meta
              name="description"
              content={intl.formatMessage({ id: "seo.description" })}
            />
            <meta name="image" content={imageUrl} />

            <meta
              property="og:title"
              content={intl.formatMessage({ id: "seo.title" })}
            />
            <meta property="og:site_name" content={siteName} />
            <meta
              property="og:description"
              content={intl.formatMessage({ id: "seo.ogDescription" })}
            />
            <meta property="og:type" content={type} />
            {intl.locale && <meta property="og:locale" content={intl.locale} />}
            <meta property="og:url" content={url} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:image:secure_url" content={imageUrl} />
            <meta property="og:image:width" content={presentationWidth} />
            <meta property="og:image:height" content={presentationHeight} />
            <meta property="og:image:alt" content={imageAlt} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={twitterHandle} />
            <meta name="twitter:creator" content={twitterHandle} />
            <meta name="twitter:title" content={twitterTitle} />
            <meta name="twitter:description" content={twitterDescription} />
            <meta name="twitter:image" content={imageUrl} />
          </Helmet>
        );
      }}
    />
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        descriptionDE
        descriptionEN
        siteName
        type
        siteUrl
        imageAlt
        twitterHandle
        twitterTitle
        twitterDescription
      }
    }
    image: file(relativePath: { eq: "seo/opengraph_image.jpeg" }) {
      id
      childImageSharp {
        fluid {
          presentationWidth
          presentationHeight
          src
        }
      }
    }
  }
`;
