import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "gatsby-plugin-react-intl";
import Menu from "../menu/menu";
import Footer from "../footer/footer";
import { Header } from "../header/header";
import "./layout.module.css";
import layout from "./layout.module.css";
import SEO from "../SEO";

const Layout = (props) => {
  const [classes, setClasses] = useState("hide");
  const intl = useIntl();

  const handleMenu = () => {
    setClasses(classes == "hide" ? "show" : "hide");
  };

  return (
    <div>
      <SEO title={props.title} path={props.path} />
      <div className={layout.mainWrapper}>
        <Header menuHandler={() => handleMenu()} />
        <Menu classes={classes} />
        {props.children}
        <Footer path={props.path} />
      </div>
    </div>
  );
};

export default Layout;
