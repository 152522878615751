import React from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";
import "./header.css";
import { FormattedMessage, Link } from "gatsby-plugin-react-intl";

export const Header = (props) => (
  <StaticQuery
    query={graphql`
      query {
        burgerButton: file(relativePath: { eq: "icons/burgerButton.png" }) {
          childImageSharp {
            fluid(maxWidth: 110) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoHeader: file(relativePath: { eq: "header/logo-header.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={({ burgerButton, logoHeader }) => (
      <header className="header">
        <div className="headerWrapper">
          <Link
            to="/"
            className="narandoLink"
            title="narando | Give your stories a voice"
          >
            <div className="narandoImage">
              <Img
                fluid={logoHeader.childImageSharp.fluid}
                alt="narando Logo"
              />
            </div>
          </Link>
          <div className="headerMenu">
            <ul>
              <li>
                <Link to="/publishers">
                  <FormattedMessage id="header.publishers" />
                </Link>
              </li>
              <li>
                <Link to="/narrators">
                  <FormattedMessage id="header.narrator" />
                </Link>
              </li>
              <li>
                <Link to="/faq">
                  <FormattedMessage id="header.faq" />
                </Link>
              </li>
              <li>
                <Link to="/about">
                  <FormattedMessage id="header.about" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="rightWrapper">
            <Link to="/contact">
              <button className="headerContact">
                <FormattedMessage id="header.contact" />
              </button>
            </Link>
            <div onClick={props.menuHandler} className="burgerButtonWrapper">
              <Img
                fluid={burgerButton.childImageSharp.fluid}
                className="burgerButton"
              />
            </div>
          </div>
        </div>
      </header>
    )}
  />
);
