import React, { Component } from "react";
import "./menu.css";
import { FormattedMessage, Link } from "gatsby-plugin-react-intl";

class Menu extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={`menu ${classes}`}>
        <ul>
          <li>
            <Link to="/publishers">
              <FormattedMessage id="header.publishers" />
            </Link>
          </li>
          <li>
            <Link to="/narrators">
              <FormattedMessage id="header.narrator" />
            </Link>
          </li>
          <li>
            <Link to="/faq">
              <FormattedMessage id="header.faq" />
            </Link>
          </li>
          <li>
            <Link to="/about">
              <FormattedMessage id="header.about" />
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default Menu;
