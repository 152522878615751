import React, { Component } from "react";
import { FormattedMessage, Link } from "gatsby-plugin-react-intl";
import footer from "./footer.module.css";
import { Facebook } from "../../assets/social/Facebook";
import { Instagram } from "../../assets/social/Instagram";
import { Twitter } from "../../assets/social/Twitter";
import { changeLocale } from "gatsby-plugin-react-intl";

class Footer extends Component {
  render() {
    return (
      <div className={footer.container}>
        <div className={footer.wrapper}>
          <div className={footer.innerWrapper}>
            <div className={footer.footerHalfWrapper}>
              <p className={footer.link}>
                &copy; narando GmbH {new Date().getFullYear()}
              </p>
              <div className={footer.footerLinkWrapper}>
                <Link to="/imprint">
                  <div className={footer.footerLink}>
                    <FormattedMessage id="header.imprint" />
                  </div>
                </Link>
                <Link to="/privacy">
                  <div className={footer.footerLink}>
                    <FormattedMessage id="header.privacy" />
                  </div>
                </Link>
                <Link to="/player-widget">
                  <div className={footer.footerLink}>
                    <FormattedMessage id="header.player-widget" />
                  </div>
                </Link>
              </div>
            </div>
            <div className={footer.footerHalfWrapper}>
              <div className={footer.language}>
                <button
                  onClick={() => changeLocale("de-DE")}
                  className={footer.switchLanguage}
                >
                  <FormattedMessage id="layout.german" />
                </button>
                |
                <button
                  onClick={() => changeLocale("en-US")}
                  className={footer.switchLanguage}
                >
                  <FormattedMessage id="layout.english" />
                </button>
              </div>
              <div className={footer.socialIconsWrapper}>
                <a
                  href="https://www.facebook.com/narandoapp/"
                  target="_blank"
                  rel="noopener"
                >
                  <Facebook />
                </a>
                <a
                  href="https://www.instagram.com/narando.audio/"
                  target="_blank"
                  rel="noopener"
                >
                  <Instagram />
                </a>
                <a
                  href="https://twitter.com/narandoapp"
                  target="_blank"
                  rel="noopener"
                >
                  <Twitter />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
